/* eslint-disable react/prop-types */
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

type ContentProps = {
  activePage: number
  setActivePage: Dispatch<SetStateAction<number>>
}

const paginationContext = createContext<ContentProps>({
  activePage: 1,
  setActivePage: () => null,
})

export const useActivePage = () => useContext(paginationContext)

export function PaginationProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const activePage: ContentProps = useActiveContent()

  return (
    <paginationContext.Provider value={activePage}>
      {children}
    </paginationContext.Provider>
  )
}

function useActiveContent() {
  const [activePage, setActivePage] = useState(1)

  return {
    activePage,
    setActivePage,
  }
}
