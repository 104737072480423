export const source = `
<header class="bg-white">
    <nav x-data="{ isOpen: false }" class="relative bg-white shadow-md">
        <div class="px-2 py-4  items-center md:flex md:justify-between md:items-center">
            <div class="flex w-full flex-col lg:flex-row  justify-between items-center">
                <div class="flex w-full items-center divide-x divide-x-2 divide-solid divide-[#0072BB]" >
                  <img class="lg:w-40 lg:h-18 w-full h-10 px-1  lg:max-w-2xl mr-3" src="https://images.squarespace-cdn.com/content/v1/5638d8dbe4b087140cc9098d/1475085290206-2P74H15MOXHWGRUUGZ4D/epicafrica+final2.jpg?format=1500w" alt="epic-africa">
                  <img class="lg:w-48 lg:h-12 w-full h-10 px-3  lg:max-w-2xl" src="https://s3.eu-central-1.amazonaws.com/epicafrica/50282aeb-6fc8-11ed-b962-0ad8d87361af-unhcr-logo-horizontal.png" alt="epic-africa">
                </div>
                <div class="mt-3 lg:flex-row" >
<button id="language" class="tracking-wider px-3 py-3 mr-2  text-sm text-white py-1 sm:capitalize lg:uppercase transition-colors duration-300 transform bg-orange-600 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500">Change Language</button>
                </div>

            </div>

        </div>
    </nav>
</header>
<section style="background-image: url('https://smartwatermagazine.com/sites/default/files/styles/thumbnail-480x263/public/flooding-in-yemen-unhcr.jpg?itok=9Cfgt43Q'); height: 32rem;" class="w-full bg-no-repeat bg-center bg-cover">
<img class="w-full h-full" src="https://smartwatermagazine.com/sites/default/files/styles/thumbnail-480x263/public/flooding-in-yemen-unhcr.jpg?itok=9Cfgt43Q" alt="epic-africa">
</section>
<section class="bg-white">
    <div class="container flex flex-col items-center px-4 py-12 mx-auto text-center">
        <h2 class="text-3xl font-bold tracking-tight text-black">
          About the CSO Mapping Project
        </h2>

        <p class="block max-w-5xl mt-4 text-xl text-black">EPIC-Africa and the United Nations High Commission for Refugees (UNHCR) - Regional Bureau for West and Central Africa are mapping local civil society organizations (CSOs) in the Sahel. The mapping project covers local CSOs in Burkina Faso, Mali, and Niger.</p>
              <p class="block max-w-5xl mt-4 text-xl text-black">The project will equip UNHCR with actionable insights into the CSO landscape in the Sahel and enable it to design appropriate strategies and tools to engage local CSOs more effectively, strengthen their institutional capacity, and increase funding to local and national partners to reach its global localization target of 25% by 2030. It will also contribute to EPIC-Africa’s efforts to create a comprehensive database of African CSOs.</p>
              <p class="block max-w-5xl mt-4 text-xl text-black">
              EPIC-Africa and UNHCR invite you to participate in the mapping project by completing this online
questionnaire.
              </p>

    </div>
</section>
  <div class="flex items-center justify-center py-4" >
<button id="language" class="tracking-wider px-3 py-5 mr-3  text-sm text-white py-1 sm:capitalize lg:uppercase transition-colors duration-300 transform bg-orange-600 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500">Change Language</button>
                </div>
<div class="container flex flex-col items-center px-4 py-12 mx-auto text-center" >
<p class="text-center text-xl" >
The Mapping project has ended

</p>
                </div>
<footer class="flex bg-blue-700 flex-col items-center justify-between p-6 bg-white  sm:flex-row">
    <a href="#" class="text-xl font-bold text-white transition-colors duration-300 hover:text-white">EPIC-Africa | UNHCR</a>

    <p class="text-sm text-white">© Copyright 2022. All Rights Reserved.</p>


</footer>
`
