import {
  CNavbar,
  CNavbarBrand,
  CImage,
  CContainer,
  CNavTitle,
} from '@coreui/react-pro'
import React from 'react'
import epicLogo from 'assets/brand/epic-logo.jpeg'
import unhcrLogo from 'assets/brand/unhcr-logo.webp'
import heroImg from 'assets/brand/hero-section.jpeg'
import { Button } from 'ui/buttons'
import { useNavigate } from 'react-router-dom'

const LandingPage = () => {
  const navigate = useNavigate()
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CNavbar className="p-3 border-bottom">
        <CNavbarBrand>
          <CImage className="sidebar-brand-full" src={epicLogo} height={45.5} />
          <CImage
            className=" mx-3 sidebar-brand-full"
            src={unhcrLogo}
            height={45.5}
          />
        </CNavbarBrand>
        <Button
          color="info"
          className="px-5"
          onClick={() => navigate('../login')}
          label="Login"
          style={{ backgroundColor: '#ef6024' }}
        />
      </CNavbar>
      <div>
        <CImage
          className="w-full"
          style={{ width: '100%' }}
          src={heroImg}
          height={650}
        />
      </div>
      <CContainer className=" my-4 d-flex align-items-center flex-column  ">
        <h3 className="fw-bold" style={{ color: '#ef6024' }}>
          CSO Database
        </h3>
        <p className="fs-4 text-center my-3">Burkina Faso - Mali - Niger.</p>
      </CContainer>
    </div>
  )
}

export default LandingPage
