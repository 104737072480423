export const source = `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
   <script src="https://cdn.tailwindcss.com"></script>
</head>
<body>

<header class="bg-white">
    <nav x-data="{ isOpen: false }" class="relative bg-white shadow-md">
        <div class="container px-6 py-4 mx-auto items-center md:flex md:justify-between md:items-center">
            <div class="flex w-full justify-between items-center">
                <div>
                  <img class="w-48 h-1/5 lg:max-w-2xl" src="https://images.squarespace-cdn.com/content/v1/5638d8dbe4b087140cc9098d/1475085290206-2P74H15MOXHWGRUUGZ4D/epicafrica+final2.jpg?format=1500w" alt="epic-africa">
                </div>
                   <button class="tracking-wider px-6 py-2.5  text-sm text-white py-1 sm:capitalize lg:uppercase transition-colors duration-300 transform bg-blue-900 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500">Getting started</button>
            </div>

        </div>
    </nav>

    <div class="container px-6 py-16 mx-auto">
        <div class="items-center lg:flex">
            <div class="w-full lg:w-1/2">
                <div class="lg:max-w-lg">
                    <h1 class="text-2xl font-semibold text-gray-800 uppercase lg:text-3xl">Building philanthropic infrastructure in Africa</h1>

                    <p class="mt-2 text-gray-600">The COVID-19 pandemic will have long-lasting implications for African civil society organisations (CSOs). The role of CSOs in response to the pandemic is more important than ever before, yet African CSOs are also dealing with the economic and social impact of this crisis. With this in mind, EPIC-Africa and @AfricanNGOs  have carried out two surveys to understand how  the COVID-19 pandemic has specifically impacted African CSOs. Over 1000 African CSOs completed the second survey between June and July 2021. The full report based on the latest survey was launched on October 28th, 2021. </p>

                    <button class="w-full h-8 mr-10 tracking-wider px-6 py-2.5 mt-6 text-sm text-white uppercase transition-colors duration-300 transform bg-orange-600 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500">  <a href="https://staging.landing.acsop.epic-africa.org/login">
                    Signin
                    </a></button>
                       <button class="w-full h-8 tracking-wider px-6 py-2.5 mt-6 text-sm text-white uppercase transition-colors duration-300 transform bg-blue-900 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500">  <a href="https://staging.landing.acsop.epic-africa.org/apply-now">
                    Signup
                    </a></button>
                </div>
            </div>

            <div class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
                <img class="w-full h-full lg:max-w-2xl" src="https://images.unsplash.com/photo-1459183885421-5cc683b8dbba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fG5nbyUyMGFmcmljYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="bana.image">
            </div>
        </div>
    </div>
</header>
<section class="bg-blue-900">
    <div class="container flex flex-col items-center px-4 py-12 mx-auto text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-600 dark:text-white">
           Advisory and Consulting Services
        </h2>

        <p class="block max-w-2xl mt-4 text-xl text-gray-500 dark:text-gray-300">EPIC-Africa offers advisory and consulting services to funders and CSOs to strengthen the African Philanthrophic sector. Find out below how we can be of service to you.</p>

    </div>
</section>
<section class="bg-white ">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl">WHAT WE DO</span></h1>

        <p class="mt-4 text-gray-500 xl:mt-6">
           Civil Society Organizations provide vital services for communities all across Africa.
Although they vary greatly in type, size and purpose, it’s widely understood that they contribute mightily to well-being on the continent.
        </p>

        <p class="mt-4 text-gray-500 xl:mt-6">
        We believe that by providing information and resources that strengthen African CSOs, we can see even more successful programs from African CSOs and tangible changes to the lives of Africans across the continent. To this end, we work in the following areas:
        </p>
        <div class="flex justify-center items-center" >
          <img class="w-full h-full lg:max-w-2xl" src="https://images.squarespace-cdn.com/content/v1/5638d8dbe4b087140cc9098d/1553196828744-S4ZGGLK3EO54P84P647B/image-asset.png?format=1500w" alt="bana.image">
        </div>

        <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
            <div class="space-y-3">
                <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                    </svg>
                </span>

                <h1 class="text-2xl font-semibold text-gray-700 capitalize">Compile data:</h1>

                <p class="text-gray-500">
                   The African CSO Index provides data on the sector, fosters collaboration and helps to grow the African philanthropic ecosystem. The index tracks, compares, and ranks CSOs across 7 measures of organizational capacity and effectiveness, making it cheaper and easier for funders to find grantee organizations.
                </p>

                <a href="#" class="inline-flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-300 transform  hover:underline hover:text-blue-600 ">
                    <span class="mx-1">read more</span>
                    <svg class="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a>
            </div>

            <div class="space-y-3">
                <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                    </svg>
                </span>

                <h1 class="text-2xl font-semibold text-gray-700 capitalize">Capacity diagnosis and planning:</h1>

                <p class="text-gray-500">
                   Our approach to capacity building is unique in that: (1) it is embedded in a framework and standards carefully tailored to fit the realities of African CSOs; and (2) it integrates capacity building with program strategy, rather than treating it as separate from and secondary to programming.
                </p>

                <a href="#" class="inline-flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-300 transform  hover:underline hover:text-blue-600 ">
                    <span class="mx-1">read more</span>
                    <svg class="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a>
            </div>

            <div class="space-y-3">
                <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                    </svg>
                </span>

                <h1 class="text-2xl font-semibold text-gray-700 capitalize">Customized Due Diligence and other advisory services: </h1>

                <p class="text-gray-500">
                 We undertake more in depth vetting of individual CSOs or cohorts of CSOs and recommend grantees to funders. Our advisory services enable funders to not only find the most credible groups but also to structure grants in ways that best support grantee capacity and sustainability.
                </p>

                <a href="#" class="inline-flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-300 transform  hover:underline hover:text-blue-600 ">
                    <span class="mx-1">read more</span>
                    <svg class="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a>
            </div>



            <div class="space-y-3">
                <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                    </svg>
                </span>

                <h1 class="text-2xl font-semibold text-gray-700 capitalize">Interpreting the philanthropic landscape:</h1>

                <p class="text-gray-500">
                  We produce commissioned and noncommissioned knowledge products (reports, country or sector mappings, capacity audits) that contribute to and enrich knowledge about African CSOs and more broadly the African philanthropic ecosystem.
                </p>

                <a href="#" class="inline-flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-300 transform  hover:underline hover:text-blue-600 ">
                    <span class="mx-1">read more</span>
                    <svg class="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a>
            </div>
        </div>
    </div>
</section>
<footer class="flex bg-blue-900 flex-col items-center justify-between p-6 bg-white  sm:flex-row">
    <a href="#" class="text-xl font-bold text-white transition-colors duration-300 hover:text-white">Epic Africa</a>

    <p class="text-sm text-white">© Copyright 2022. All Rights Reserved.</p>


</footer>
</body>
</html>
`
