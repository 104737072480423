/* eslint-disable react/prop-types */
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

type ContentProps = {
  activeKey: number
  setActiveKey: Dispatch<SetStateAction<number>>
}

const contentContext = createContext<ContentProps>({
  activeKey: 1,
  setActiveKey: () => null,
})

export const useActiveContentType = () => useContext(contentContext)

export function ContentProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const activeKey: ContentProps = useActiveContent()

  return (
    <contentContext.Provider value={activeKey}>
      {children}
    </contentContext.Provider>
  )
}

function useActiveContent() {
  const [activeKey, setActiveKey] = useState(1)

  return {
    activeKey,
    setActiveKey,
  }
}
