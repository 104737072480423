import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import AxiosProvider from 'api/context/axios-context'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ContentProvider } from 'api/context/content-context'
import { ProfileProvider } from 'api/context/profile-context'
import { PaginationProvider } from 'api/context/pagination-provider'

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
  <Provider store={store}>
    <AxiosProvider>
      <ContentProvider>
        <ProfileProvider>
          <PaginationProvider>
            <QueryClientProvider client={client}>
              <App />
            </QueryClientProvider>
          </PaginationProvider>
        </ProfileProvider>
      </ContentProvider>
    </AxiosProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
