import { CLoadingButton } from '@coreui/react-pro'
import React, { FC } from 'react'

type ButtonProps = {
  onClick: () => void
  label: string
  color: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger'
  className?: string
  loading: boolean
  size?: 'sm' | 'lg'
  variant?: 'outline' | 'ghost'
}

const LoadingButton: FC<ButtonProps> = ({
  onClick,
  label,
  className,
  variant,
  size,
  ...props
}) => {
  return (
    <CLoadingButton
      className={`text-white ${className}`}
      onClick={onClick}
      {...props}
    >
      {label}
    </CLoadingButton>
  )
}

export default LoadingButton
