import { BASE_API_URL } from 'utils/app-constants'

export const USER_URL = `${BASE_API_URL}/user`
export const REGISTER_URL = `${BASE_API_URL}/auth/register`
export const ADMIN_REGISTER_URL = `${BASE_API_URL}/admin-account`
export const LOGIN_URL = `${BASE_API_URL}/auth/login`
export const ADMIN_LOGIN_URL = `${BASE_API_URL}/auth/admin/login`
export const ROLES_URL = `${BASE_API_URL}/role`
export const CAMPAIGN_URL = `${BASE_API_URL}/campaign`
export const SURVEY_URL = `${BASE_API_URL}/stage`
export const FORM_URL = `${BASE_API_URL}/form`
export const QUESTIONS_URL = `${BASE_API_URL}/question`
export const FIELDS_URL = `${BASE_API_URL}/field`
export const CHOICES_URL = `${BASE_API_URL}/choice`
export const LANDING_PAGE_URL = `${BASE_API_URL}/landing-page`
export const RESPONSES_URL = `${BASE_API_URL}/response`
export const USER_RESPONSES_URL = `${BASE_API_URL}/user/response`
export const COHORT_URL = `${BASE_API_URL}/cohort`
export const COHORT_DEFS_URL = `${BASE_API_URL}/attribute?type=search`
export const ADDITIONAL_FIELD_URL = `${BASE_API_URL}/additionalfield`
export const CSO_PROFILE_URL = `${BASE_API_URL}/cso-profile`
export const EMAIL_URL = `${BASE_API_URL}/contact/email`
export const TEMPLATES_URL = `${BASE_API_URL}/email-template`
export const ARTICLE_URL = `${BASE_API_URL}/content/article`
export const JOBS_URL = `${BASE_API_URL}/content/opportunity`
export const FOCUS_AREA_URL = `${BASE_API_URL}/area-of-focus`
export const EVENTS_URL = `${BASE_API_URL}/content/event`
export const CONTENT_STATUS_URL = `${BASE_API_URL}/content-status`
export const JOB_URL = `${BASE_API_URL}/content/opportunity`
export const EVENT_URL = `${BASE_API_URL}/content/event`
export const SING_ARTICLE_URL = `${BASE_API_URL}/content/article`
export const COUNTRIES_URL = `${BASE_API_URL}/country`
export const VACANCY_URL = `${BASE_API_URL}/content/vacancy`
export const USER_PROFILE_URL = `${BASE_API_URL}/cso-registration-profile`
export const USERS_ACCOUNTS_URL = `${BASE_API_URL}/user-account`
export const USERS_ACCOUNT_ROLES_URL = `${BASE_API_URL}/user-account-role`
export const ORGANISATION_URL = `${BASE_API_URL}/organisation`
export const ADMIN_ROLES = `${BASE_API_URL}/admin-account-role`
export const ALIAS_MAP = `${BASE_API_URL}/question-alias`
