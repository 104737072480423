export const source = `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
   <script src="https://cdn.tailwindcss.com"></script>
</head>
<body>

<header class="bg-white">
    <nav x-data="{ isOpen: false }" class="relative bg-white shadow-md">
        <div class="container px-6 py-4 mx-auto items-center md:flex md:justify-between md:items-center">
            <div class="flex w-full justify-between items-center">
                <div>
                  <img class="w-48 h-1/5 lg:max-w-2xl" src="https://images.squarespace-cdn.com/content/v1/5638d8dbe4b087140cc9098d/1475085290206-2P74H15MOXHWGRUUGZ4D/epicafrica+final2.jpg?format=1500w" alt="epic-africa">
                </div>
                <div class="flex" >
                    <button class="w-full h-8 tracking-wider mr-3 font-bold border-orange-600 border-2 px-6 py-2.5 text-sm text-white uppercase transition-colors duration-300 transform bg-orange-600 rounded-sm lg:w-auto hover:bg-orange-500 focus:outline-none focus:bg-orange-500">  <a href="https://staging.landing.acsop.epic-africa.org/apply-now">
                    Signup
                    </a></button>
                    <button class="w-full h-8 tracking-wider font-bold px-6 py-2.5 text-sm text-white uppercase transition-colors duration-300 text-blue-900 border-blue-900 border-2 transform bg-white rounded-sm lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500">  <a href="https://staging.landing.acsop.epic-africa.org/login">
                    Signin
                    </a></button>
                </div>
            </div>

        </div>
    </nav>


</header>
<section class="bg-blue-900" >
 <div class="px-32 py-16 ">
        <div class="items-center flex-col justify-center flex">

                    <h1 class="text-2xl text-center font-semibold text-white uppercase lg:text-3xl">Building philanthropic infrastructure in Africa</h1>

                    <p class="mt-2 text-white text-center">The COVID-19 pandemic will have long-lasting implications for African civil society organisations (CSOs). The role of CSOs in response to the pandemic is more important than ever before, yet African CSOs are also dealing with the economic and social impact of this crisis. With this in mind, EPIC-Africa and @AfricanNGOs  have carried out two surveys to understand how  the COVID-19 pandemic has specifically impacted African CSOs. Over 1000 African CSOs completed the second survey between June and July 2021. The full report based on the latest survey was launched on October 28th, 2021. </p>


        </div>
    </div>
</section>

<section class="bg-gray-100" >
 <div class="px-3 py-8 items-center justify-center flex flex-col md:flex-col lg:flex-row">
        <div class=" w-full lg:w-1/2 flex-col flex">

                    <h1 class="text-2xl  font-semibold text-gray-600 uppercase lg:text-3xl">We offer Actionable data on the African CSO sector</h1>

                    <p class="mt-2 text-gray-600 ">EPIC-Africa seeks to enhance philanthropic impact by filling critical data and capacity gaps in the philanthropic market infrastructure in Africa. We help to propel African civil society organizations to higher standards of performance, accountability, and transparency while inspiring greater local legitimacy and support.</p>

   <div class="flex my-5" >
                    <button class="w-full h-8 tracking-wider mr-3 font-bold border-orange-600 border-2 px-6 py-2.5 text-sm text-white uppercase transition-colors duration-300 transform bg-orange-600 rounded-sm lg:w-auto hover:bg-orange-500 focus:outline-none focus:bg-orange-500">  <a href="https://staging.landing.acsop.epic-africa.org/signup">
                  Read more about us
                    </a></button>
                    <button class="w-full h-8 tracking-wider font-bold px-6 py-2.5 text-sm text-white uppercase transition-colors duration-300 text-blue-900 border-blue-900 border-2 transform bg-white rounded-sm lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500">  <a href="https://staging.landing.acsop.epic-africa.org/login">
                    View more reports
                    </a></button>
                </div>
        </div>
          <img class="lg:w-3/4 w-full  h-full lg:max-w-2xl" src="https://images.unsplash.com/photo-1552710307-537199cd41c0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGVwaWMlMjBhZnJpY2ElMjBuZ298ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" alt="epic-africa">



    </div>
</section>
<section class="bg-gray-200">
    <div class="">
        <div class="container px-6 py-10 mx-auto">
            <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl">Our Partners</h1>

            <div class="flex justify-center mx-auto mt-6">
                <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                <span class="inline-block w-3 h-1 mx-1 bg-blue-500 rounded-full"></span>
                <span class="inline-block w-1 h-1 bg-blue-500 rounded-full"></span>
            </div>

            <p class="max-w-2xl mx-auto mt-6 text-center text-gray-800">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo incidunt ex placeat modi magni quia error alias, adipisci rem similique, at omnis eligendi optio eos harum.
            </p>
        </div>
    </div>

    <div class="px-6 py-10 mx-auto">
        <div class="grid grid-cols-1 gap-8 mt-2 xl:mt-4 md:grid-cols-2 xl:grid-cols-3">
            <div class="flex flex-col items-center p-4 border sm:p-6 rounded-xl dark:border-gray-700">
                <img class="object-cover w-full rounded-xl" src="https://www.globalgiving.org/img/logos/gg_horizontal_color_600.png" alt="">
            </div>

            <div class="flex flex-col items-center p-4 border sm:p-6 rounded-xl dark:border-gray-700">
                <img class="object-cover w-full rounded-xl " src="https://www.globalgiving.org/img/logos/gg_horizontal_color_600.png" alt="">
            </div>

            <div class="flex flex-col items-center p-4 border sm:p-6 rounded-xl dark:border-gray-700">
                <img class="object-cover w-full rounded-xl " src="https://www.globalgiving.org/img/logos/gg_horizontal_color_600.png" alt="">
            </div>
        </div>
    </div>
</section>
<footer class="flex bg-whit flex-col items-center justify-between p-6 bg-white  sm:flex-row">
    <a href="#" class="text-xl font-bold text-gray-800 transition-colors duration-300 hover:text-white">Epic Africa</a>
    <p class="text-sm text-gray-700">© Copyright 2022. All Rights Reserved.</p>
</footer>
</body>
</html>
`
