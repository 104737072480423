import { source as p1 } from './data/page-1'
import { source as p2 } from './data/page-2'
import { source as p3 } from './data/page-3'
import { source as p4 } from './data/page-4'
import { hypedata } from './data/rawData'

const HyperUI = hypedata.map((data, index) => {
  return {
    id: `${data.displayName}_${index}`,
    label: data.displayName,
    content: data.source,
    category: data.category,
  }
})

const sources = [
  ...HyperUI,
  {
    id: 'page-block-4',
    class: '',
    label: 'UNHCR complete',
    content: p4,
    category: 'Landing Pages',
    // order: 1
  },

  {
    id: 'page-block-3',
    class: '',
    label: 'UNHCR page',
    content: p3,
    category: 'Landing Pages',
    // order: 1
  },
  {
    id: 'page-block-1',
    class: '',
    label: 'Sample page one',
    content: p1,
    category: 'Landing Pages',
    // order: 1
  },
  {
    id: 'page-block-2',
    class: '',
    label: 'Sample page two',
    content: p2,
    category: 'Landing Pages',
    // order: 1
  },

  // {
  //   id: 'footer-block-1',
  //   class: '',
  //   label: 'Footer 1',
  //   media: z1s,
  //   content: z1,
  //   category: 'Footer',
  //   // order: 1
  // },
  // {
  //   id: 'footer-block-2',
  //   class: '',
  //   media: z2s,
  //   label: 'Footer 2',
  //   content: z2,
  //   category: 'Footer',
  //   // order: 1
  // },

  // {
  //   id: 'header-block-1',
  //   class: '',
  //   media: h1s,
  //   label: 'Header 1',
  //   content: h1,
  //   category: 'Header',
  //   // order: 1
  // },
  // {
  //   id: 'header-block-2',
  //   class: '',
  //   label: 'Footer 2',
  //   media: h2s,
  //   content: h2,
  //   category: 'Header',
  //   // order: 1
  // },

  // {
  //   id: 'hero-block-1',
  //   class: '',
  //   media: r1s,
  //   label: 'Hero 1',
  //   content: r1,
  //   category: 'Hero',
  //   // order: 1
  // },
  // {
  //   id: 'hero-block-2',
  //   class: '',
  //   media: r2s,
  //   label: 'Hero 1',
  //   content: r2,
  //   category: 'Hero',
  //   // order: 1
  // },
  // {
  //   id: 'hero-block-3',
  //   class: '',
  //   media: r2s,
  //   label: 'Hero 3',
  //   content: r3,
  //   category: 'Hero',
  //   // order: 1
  // },
]

export default (editor, options = {}) => {
  const bm = editor.Blocks

  sources.forEach((s) => {
    bm.add(s.id, {
      label: s.label,
      attributes: { class: s.class },
      content: s.content,
      category: { label: s.category, open: s.category === 'Blog' },
      media: s.media,
    })
  })
}
