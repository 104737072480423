/* eslint-disable react/prop-types */
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

type ContentProps = {
  activeKey: number
  setActiveKey: Dispatch<SetStateAction<number>>
}

const profileContext = createContext<ContentProps>({
  activeKey: 1,
  setActiveKey: () => null,
})

export const useActiveProfile = () => useContext(profileContext)

export function ProfileProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const activeKey: ContentProps = useActiveContent()

  return (
    <profileContext.Provider value={activeKey}>
      {children}
    </profileContext.Provider>
  )
}

function useActiveContent() {
  const [activeKey, setActiveKey] = useState(1)

  return {
    activeKey,
    setActiveKey,
  }
}
