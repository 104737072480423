import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import React, { createContext, useContext, useMemo } from 'react'
import { STORAGE_KEYS } from 'utils/storage-keys'

const axiosInstance = Axios.create({
  headers: {
    'Content-type': 'application/json',
  },
})

export const AxiosContext = createContext<AxiosInstance>(axiosInstance)

const AxiosProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const axios: AxiosInstance = useMemo(() => {
    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
      // Read token for anywhere, in this case directly from localStorage
      const token = localStorage.getItem(STORAGE_KEYS.EPIC_ADMIN_ACCESS_TOKEN)
      if (token) {
        config.headers = {
          Authorization: `Bearer ${token?.replace(/(^"|"$)/g, '')}`,
        }
      }

      return config
    })
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error?.response?.status
        if (status === 401 || status === 422) {
          localStorage.removeItem(STORAGE_KEYS.EPIC_ADMIN_ACCESS_TOKEN)
          localStorage.removeItem(STORAGE_KEYS.EPIC_ADMIN_CURRENT_USER)
          localStorage.removeItem(STORAGE_KEYS.EPIC_ADMIN_REFRESH_TOKEN)
          window.location.replace('/login')
        }
        throw error
      },
    )

    return axiosInstance
  }, [])

  return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
}

export function useAxios() {
  return useContext(AxiosContext)
}

export default AxiosProvider
