/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react'
import Grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'

import EditorConfig from 'views/campaign/landingPageBuilder/EditorConfig'
import './editor.css'
import { CCol, CContainer, CRow } from '@coreui/react-pro'
import { Button } from 'ui/buttons'
import { useAxios } from 'api/context/axios-context'
import { useNavigate, useParams } from 'react-router-dom'
import { LANDING_PAGE_URL } from 'api/urls'

const Editor: FC = () => {
  const [editor, setEditor] = useState<any>()
  const axios = useAxios()
  const { id } = useParams()
  const navigate = useNavigate()
  const loadComponents = (editor: any) => {
    editor.Panels.addButton('options', {
      id: 'update-theme',
      className: 'fa fa-adjust',
      command: 'open-update-theme',
      attributes: {
        title: 'Update Theme',
        'data-tooltip-pos': 'bottom',
      },
    })

    editor.Storage.add('remote', {
      async load() {
        const {
          data: { returned_resultset },
        } = await axios.get(`${LANDING_PAGE_URL}/${id}`)
        console.log(returned_resultset[0])

        return JSON.parse(returned_resultset[0]?.content_fr)
      },

      async store(data: any) {
        // console.log(data)
        const html = editor.getHtml()
        let css = ''
        editor.runCommand('get-tailwindCss', (twcss: any) => (css = twcss))
        console.log(css, 'html')

        const obj = { html: html, css: 'css' }

        const payload = {
          content: JSON.stringify(obj),
          content_fr: JSON.stringify(data),
        }
        return axios.put(`${LANDING_PAGE_URL}/${id}`, payload)
      },
    })

    setEditor(editor)
  }

  useEffect(() => {
    const loadEditor = async () => {
      //@ts-ignore
      const editorMain: Grapesjs.Editor = Grapesjs.init(EditorConfig())
      loadComponents(editorMain)
    }
    loadEditor()
  }, [])

  const getHtml = () => {
    navigate(-1)
  }

  return (
    <CContainer fluid>
      <CRow className="mb-1">
        <CCol>
          <Button label="Done" onClick={getHtml} color="info" />
        </CCol>
      </CRow>
      <div id="gjs"></div>
    </CContainer>
  )
}

export default Editor
