import { CButton } from '@coreui/react-pro'
import { CLoadingButtonProps } from '@coreui/react-pro/dist/components/loading-button/CLoadingButton'

import React, { FC } from 'react'

interface IButtonProps extends CLoadingButtonProps {
  onClick: () => void
  label: string
  color: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger'
  variant?: 'outline' | 'ghost'
  className?: string
  size?: 'sm' | 'lg'
}

const Button: FC<IButtonProps> = ({
  onClick,
  label,
  variant,
  className,
  ...rest
}) => {
  return (
    <CButton
      className={
        variant === 'outline'
          ? `text-blue ${className}`
          : `text-white ${className}`
      }
      onClick={onClick}
      variant={variant}
      {...rest}
    >
      {label}
    </CButton>
  )
}

export default Button
